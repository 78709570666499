




























import { Component, Inject, Prop, PropSync, Vue } from 'vue-property-decorator'
import { SubscribeConfig } from '@kluseg/trackify/dist/main'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../support'

import { NewsletterFormState } from '../Newsletter.contracts'
import { NewsletterModuleConfig } from '../Newsletter.config'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({
  name: 'Rich'
})
export class Rich extends Vue {
  @PropSync('configuration', { type: Object, required: true })
  public readonly _config!: NewsletterModuleConfig

  @Prop({ type: Boolean, required: true })
  public readonly isLoading!: boolean

  @Prop({ type: Function, required: true })
  public readonly clearState!: boolean

  @Prop({ type: Function, required: true })
  public readonly subscribe!: boolean

  @Prop({ type: Object, required: true })
  public readonly formData!: SubscribeConfig

  @Prop({ type: String, required: false, default: null })
  public readonly state?: NewsletterFormState

  @Prop({ type: String, required: true })
  public readonly theme!: string

  @Prop({ type: String, required: true })
  public readonly message!: string

  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  protected readonly isMobile!: () => boolean
}

export default Rich
